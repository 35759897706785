import * as React from 'react';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { AppBar, Toolbar, Typography, Tab, Tabs, useTheme } from '@mui/material';
import LayeredPeaks from './assets/layered-peaks-haikei.svg';
import './App.css';
import About from './pages/about/About';
import { useNavigate, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Projects from './pages/projects/Projects';
import Contact from './pages/contact/Contact';

const DEFAULT_ROUTE = 'about';

export default function App() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const currentPath = location.pathname === '/' ? `/${DEFAULT_ROUTE}` : location.pathname;

  return (
    <div className='App'>
      <AppBar position='static'>
        <Toolbar id='top-bar'>
          <div id='title-section'>
            <WorkOutlineIcon fontSize='large' id='logo'></WorkOutlineIcon>

            <div>
              <Typography id='title' variant='h1' component='div' sx={{ flexGrow: 1 }}>
                My Resume
              </Typography>
              <p id='subtitle'>Connor Skees</p>
            </div>
          </div>
          <div id='navigation'>
            <Tabs textColor='secondary' onChange={(_, value) => navigate(value)} indicatorColor='secondary' value={currentPath}>
              <Tab 
                sx={{ color: theme.palette.primary.contrastText }} 
                value={'/about'} 
                label='About' 
              />
              <Tab 
                sx={{ color: theme.palette.primary.contrastText }} 
                value={'/projects'} 
                label='Projects' 
              />

              <Tab 
                sx={{ color: theme.palette.primary.contrastText }} 
                value={'/contact'} 
                label='Contact' 
              />
            </Tabs>
          </div>
        </Toolbar>
      </AppBar>

      <Routes>
        <Route path='/' element={<Navigate to={DEFAULT_ROUTE} />} />
        <Route path='about' element={<About></About>} />
        <Route path='projects' element={<Projects></Projects>} />
        <Route path='contact' element={<Contact></Contact>} />
      </Routes>

      {
        // Generated With https://app.haikei.app/
      }
      {currentPath !== '/contact' && <img alt='Layered Background' id='layeredBackground' src={LayeredPeaks} />}
    </div>
  );
}
