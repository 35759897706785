import { Kubernetes, Docker, ReactTool, Ionic, Firebase, Gitlab, Angular } from '../../components/tool/Tools';
import { IProject } from './Projects';

import cluster1 from '../../assets/projects/cluster1.jpg';
import cluster2 from '../../assets/projects/cluster2.jpg';
import portal1 from '../../assets/projects/portal1.jpg';
import portal2 from '../../assets/projects/portal2.jpg';
import shared1 from '../../assets/projects/shared-1.jpg';
import shared2 from '../../assets/projects/shared-2.png';
import weightLoss1 from '../../assets/projects/weight-loss-1.jpg';
import weightLoss2 from '../../assets/projects/weight-loss-2.png';
import restaurant1 from '../../assets/projects/restaurant-1.jpg';
import restaurant2 from '../../assets/projects/restaurant-2.png';

export const projectList: IProject[] = [
  {
    title: 'Mini Kubernetes Cluster',
    description: 'I built this project to learn about how to scale in any cloud environment. Redundancy was built-in with each node handling web servers, databases, file storage, and game servers. Right after this project, I applied what I learned to the existing infrastructure at my company.',
    toolsUsed: [Kubernetes, Docker],
    images: [cluster1, cluster2],
  },
  {
    title: 'IOT Stereo System',
    description: 'The portal window has a cryptocurrency price API, a weather API, and smart home integration built-in. When playing records, it also functions as a stereo system communicating with an audio interface and acting as a WiFi audio player.',
    toolsUsed: [ReactTool, Ionic, Firebase, Gitlab],
    images: [portal2, portal1],
  },
  {
    title: 'Shared Music Taste',
    description: `While finding music in common can be a difficult task, this project was a great opportunity to use the Spotify API to make a recommendation engine.`,
    toolsUsed: [Angular, Ionic, Firebase, Gitlab],
    images: [shared1, shared2],
    url: 'https://shared-interests.web.app',
  },
  {
    title: 'Image Compression Tool',
    description: `A simple tool that can reduce image storage by 70% without noticeable loss of quality.`,
    toolsUsed: [Angular, Ionic, Firebase, Gitlab],
    images: [weightLoss1, weightLoss2],
    url: 'https://weight-loss-for-images.web.app/',
  },
  {
    title: 'Restaurant Ordering Site',
    description: `I led a small team to help develop a restaurant ordering native app and site. From being able to connect with a printer and tablet in the kitchen, this tool allowed me to learn design and make the architecture of a system.`,
    toolsUsed: [Angular, Ionic, Firebase, Gitlab],
    images: [restaurant1, restaurant2],
    url: 'https://demo.dev.3bapps.com/',
  },
];
