import { Typography } from '@mui/material';
import myImage from '../../assets/me.jpg';
import './About.css';

import { Angular, Docker, Firebase, Ionic, Kubernetes, ReactTool, Node } from '../../components/tool/Tools';

export default function About() {
  return (
    <div className='container'>
      <div className='topRow'>
        <div className='leftBlock'>
          <Typography id='name' variant='h2' component='div' sx={{ flexGrow: 1 }}>
            Connor Skees
          </Typography>
          <p className='aboutText'>&emsp;In 2015 while being in high-school, I co-founded 3BApps learning to program by making restaurant ordering apps. With 3Bapps being in the North East Indiana Innovation Center, it let me work with many skilled developers. I love to find better ways of solving problems.</p>
        </div>
        <div className='imageBlock'>
          <img alt='Me' id='my-image' src={myImage}></img>
        </div>
      </div>
      <div>
        <Typography variant='h3' component='div' sx={{ flexGrow: 1 }}>
          Tools I love
        </Typography>

        <div id='tools'>
          <Angular></Angular>
          <ReactTool></ReactTool>
          <Node></Node>
          <Kubernetes></Kubernetes>
          <Docker></Docker>
          <Ionic></Ionic>
          <Firebase></Firebase>
        </div>
      </div>
    </div>
  );
}
