import { projectList } from './project-list';
import Project from './project/Project';
import './Projects.css';

export interface IProject {
  title: string;
  description?: string;
  toolsUsed: (() => JSX.Element)[];
  images?: string[];
  url?: string;
}

export default function Projects() {
  return (
    <div id='container'>
      {projectList.map((project, i) => (
        <div className='project' key={i}>
          <Project project={project}></Project>
        </div>
      ))}
    </div>
  );
}
