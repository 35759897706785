import './Tool.css';

export default function Tool(logo: any) {
  return (
    <div className='tool'>
      <img 
        alt='Tool' 
        className='toolImage' 
        src={logo.image} />
    </div>
  );
}
