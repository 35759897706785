import { animated, useSpring } from 'react-spring';
import { useState } from 'react';

export default function Hover(props: { children: any }) {
  const [isHover, setIsHover] = useState(false);

  const style = useSpring({
    display: 'inline-block',
    transform: isHover ? `scale(1.1)` : `scale(1)`,
  });

  return (
    <animated.span
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={style}
    >
      {props.children}
    </animated.span>
  );
}
