import { Paper, Typography } from '@mui/material';
import { IProject } from '../Projects';
import './Project.css';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import LinkIcon from '@mui/icons-material/Link';

export default function Project(props: { project: IProject }) {
  return (
    <div>
      <Paper 
        sx={{ borderRadius: '0.5em', display: 'flex' }} 
        elevation={5}
      >
        <div className="info">
          <Typography 
              variant="h4" 
              component="div" sx={{ flexGrow: 1, display: 'flex' }}
            >
            {props.project.title}
            {props.project.url && (
              <a 
                target='_blank' 
                href={props.project.url}
                rel='noreferrer'
              >
                <LinkIcon className="linkIcon" />
              </a>
            )}
          </Typography>
          {props.project.description && <p>{props.project.description}</p>}

          <div className="tools">
            {props.project.toolsUsed.map((Tool, i) => (
              <Tool key={i}></Tool>
            ))}
          </div>
          {props.project.images && (
            <div className="images">
              {props.project.images.map((image, i) => (
                <Zoom key={i}>
                  <img alt='Project' className="regularImage" src={image}></img>
                </Zoom>
              ))}
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
}
