import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    fontWeightRegular: 400,
  },
  palette: {
    primary: {
      light: '#353535',
      main: '#0f0f0f',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffffff',
      main: '#ebf4f4',
      dark: '#b9c1c1',
      contrastText: '#000',
    },
  },
});

theme.typography.h1 = {
  fontFamily: 'Montserrat',
  fontSize: '1.5rem',
};

theme.typography.h2 = {
  fontFamily: 'Montserrat',
  fontWeight: '300',
  fontSize: '2rem',
};

theme.typography.h3 = {
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  fontSize: '1.5rem',
};

theme.typography.h4 = {
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  fontSize: '1.1rem',
};
