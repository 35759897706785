import Tool from './Tool';

import AngularLogo from '../../assets/tools/angular-logo.png';
import IonicLogo from '../../assets/tools/ionic-logo.png';
import ReactLogo from '../../assets/tools/react-logo.png';
import JavaLogo from '../../assets/tools/java-logo.png';
import JavascriptLogo from '../../assets/tools/js-logo.png';
import KubernetesLogo from '../../assets/tools/kubernetes-logo.png';
import TypescriptLogo from '../../assets/tools/typescript-logo.png';
import FirebaseLogo from '../../assets/tools/firebase-logo.png';
import DockerLogo from '../../assets/tools/docker-logo.png';
import GitlabLogo from '../../assets/tools/gitlab-logo.png';
import NodeLogo from '../../assets/tools/node-logo.png';
import SwiftLogo from '../../assets/tools/swift-logo.png';
import CSharpLogo from '../../assets/tools/c-sharp-logo.png';

export const Angular = () => <Tool image={AngularLogo}></Tool>;
export const Ionic = () => <Tool image={IonicLogo}></Tool>;
export const ReactTool = () => <Tool image={ReactLogo}></Tool>;
export const Kubernetes = () => <Tool image={KubernetesLogo}></Tool>;
export const Firebase = () => <Tool image={FirebaseLogo}></Tool>;
export const Docker = () => <Tool image={DockerLogo}></Tool>;
export const Gitlab = () => <Tool image={GitlabLogo}></Tool>;
export const Node = () => <Tool image={NodeLogo}></Tool>;

export const Java = () => <Tool image={JavaLogo}></Tool>;
export const Javascript = () => <Tool image={JavascriptLogo}></Tool>;
export const Typescript = () => <Tool image={TypescriptLogo}></Tool>;
export const CSharp = () => <Tool image={CSharpLogo}></Tool>;
export const Swift = () => <Tool image={SwiftLogo}></Tool>;
