import { Paper, Typography } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import './Contact.css';
import Background from '../../assets/kellen-riggin-vwPiVcHdkgc-unsplash.jpg';
import Hover from './Hover';
import Typewriter from 'typewriter-effect';
let hasPlayed = false;

export default function Contact() {
  return (
    <div style={{ backgroundImage: `url(${Background})` }} className='contact'>
      <div className='contactContainer'>
        <Typography color='white' variant='h2' component='div' sx={{ flexGrow: 1, display: 'flex' }}>
          Let's Get In Touch
        </Typography>
        <div className='subtitle'>
          {hasPlayed ? (
            <div>Feel free to reach out anytime.</div>
          ) : (
            <Typewriter
              options={{ delay: 30 }}
              onInit={(typewriter) => {
                if (!hasPlayed) {
                  typewriter
                    .typeString('Feel free to reach out anytime.')
                    .start()
                    .callFunction(() => {
                      hasPlayed = true;
                    });
                }
              }}
            />
          )}
        </div>
        <div id='contact-methods'>
          <Hover>
            <Paper className='contactMethod' elevation={3}>
              <MailOutlineIcon />
              <a 
                target='_blank' 
                href='mailto:ConnorsApps@yahoo.com'
                rel='noreferrer'
              >
                ConnorsApps@yahoo.com
              </a>
            </Paper>
          </Hover>
          <div id='other-social'>
            <Hover>
              <Paper className='contactMethod' elevation={3}>
                <LinkedInIcon />
                <a 
                  target='_blank' 
                  href='https://www.linkedin.com/in/connor-skees'
                  rel='noreferrer'
                >
                  Linked In
                </a>
              </Paper>
            </Hover>
            <Hover>
              <Paper className='contactMethod' elevation={3}>
                <GitHubIcon />
                <a 
                  target='_blank' 
                  href='https://github.com/ConnorsApps'
                  rel='noreferrer'
                >
                  Github
                </a>
              </Paper>
            </Hover>
            <Hover>
              <Paper className='contactMethod' elevation={3}>
                <LinkedInIcon />
                <a 
                  target='_blank' 
                  href='https://www.facebook.com/connor.skees.92'
                  rel='noreferrer'
                >
                  Facebook
                </a>
              </Paper>
            </Hover>
          </div>
        </div>
      </div>
    </div>
  );
}
